import React from 'react';
import styles from './ProgressVideo.module.css';

export default function ProgressVideo() {
    return(
        <div className={styles.container} >
            <div>
                <p>
                    <b>Si tienes dudas, ve el siguiente video y sigue las instrucciones.</b>
                </p>
            </div>
            <div className={styles.video}>
                <iframe height="315" src="https://www.youtube.com/embed/QoUQlotwSi0"
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div> 
        </div>
    );
};