import React from 'react';
import style from './RequiredInfo.module.css';
import Arrow from '../../img/arrows/flecha.svg';

export default function RequiredInfo() {
    return(
        <div className={style.container + ' margin-top-md'}>
            <div className={style['sub-container'] + ' container-margin'}>
                <div className={style['container-left']}>
                    <div className={style['arrow-down']}>
                        <img src={Arrow} alt="arrow" width={35}/>
                    </div>
                    <div className={style.title}>
                        Requisitos
                    </div>
                    <div className={style.text}>
                        <ul>
                            <li>Estudiantes de bachillerato tecnológico o universitario o recién egresado (Máximo 2 años).</li>
                            <li>Ser mayor de edad.</li>
                            <li>Disponibilidad de tiempo para tomar clases online.</li>
                            <li>10 a 15 horas de autoestudio a la semana en plataforma.</li>
                            <li>Acceso a Internet y a computadora (PC/Laptop).</li>
                            <li>Con ganas de crecer, comprometidos con su desarrollo personal y profesional.</li>
                        </ul>
                    </div>
                </div>
                <div className={style['container-right']}>
                    <div className={style.title}>
                        Agenda de las clases en vivo del próximo programa <br/> Del 4 de febrero al 13 de febrero<br/>
                    </div>
                    <br />
                    <div className={style.text}>
                        <p>
                            <b>¿Cuándo?</b> martes y jueves<br />
                            <b>Horario:</b> 16:00 a 18:00 hrs. (hora central CDMX) <br />
                            <b>¿Dónde?</b> En vivo online desde tu perfil de Súbete a la Nube.
                            <br />
                            <br />
                            (Cupo limitado, INROADS se reserva el derecho de admisión)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
