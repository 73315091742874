import React, {useState} from 'react';
import style from './Footer.module.css';
import logo from "../../img/logos/inroads_isologo.svg";
import FacebookIcon from '../../img/redes/footer/facebook.svg';
import LinkedInIcon from '../../img/redes/footer/linkedin.svg';
import InstaIcon from '../../img/redes/footer/insta.svg';
import Youtube from '../../img/redes/footer/icono_YT.svg';
import Whats from '../../img/redes/footer/icono_wapp.svg';
import Button from '../../components/Button/Button';
import ModalSupport from '../../components/ModalSuport/ModalSupport';

export default function Footer() {
    const [abrirModal, setAbrirModal] = useState(false);
    
    const handleOpenSupport = () => {
        setAbrirModal(true);
    };

    const handleCloseSupport = () => {
        setAbrirModal(false);
    };

    return(
        <div className={style.container}>
            <div className={style['container-button']}>
                <Button backGroundColor={'#fbbd05'} onClick={handleOpenSupport}>
                    Soporte y  Ayuda
                </Button>
                <ModalSupport openModal={abrirModal} onClose={handleCloseSupport}/>
            </div>
            <div className={style['container-footer']}>
                <div className={style['container-footer_data']}>
                    <div>
                        <span>
                            <a href="https://inroads.org.mx/" style={{color: "white"}}>
                                <img src={logo} alt="Inroads" width={100} className='logo' style={{minHeight: "120px"}}/>
                            </a>
                        </span>
                    </div>
                    <div>
                        <p> <a href="https://inroads.org.mx/nosotros" style={{color: "white"}}> Nosotros </a></p>
                        <p> <a href="https://inroads.org.mx/formacion-de-jovenes/" style={{color: "white"}}> Programas </a></p>
                        <p> <a href="https://inroads.org.mx/mentorias-corporativas-mentee/" style={{color: "white"}}> Soy Mentee </a></p>
                        <p> <a href="https://inroads.org.mx/mentorias-corporativas-mentor/" style={{color: "white"}}> Quiero ser Mentor </a></p>
                        <p> <a href="https://inroads.org.mx/vinculacion-con-empresas" style={{color: "white"}}> Soy empresa </a></p>
                    </div>
                    <div>
                        <p> <a href="https://www.tfaforms.com/5115099?_gl=1*43si9i*_gcl_au*OTE5MzkyNzg2LjE3MDUzNzAyMjUuNDkzMjkzODEwLjE3MTA0MzUzMzIuMTcxMDQzNTM1Mw..*_ga*MTY3NzA5MDUwLjE3MDUzNzAyMjU.*_ga_RJMH9BGVQ1*MTcxMjg1NjcxMC4xMzUuMS4xNzEyODU2NzQxLjI5LjAuMA." style={{color: "white"}}> Pre-inscripción </a></p>
                        <p> <a href="https://inroads.org.mx/comunidad-inroader/" style={{color: "white"}}> Comunidad </a></p>
                        <p> <a href="https://inroads.org.mx/convocatorias-y-eventos/" style={{color: "white"}}> Convocatorias y eventos </a></p>
                        <p> <a href="https://inroads.org.mx/donaciones/" style={{color: "white"}}> Dona Hoy </a></p>
                        <p> <a href="https://inroads.org.mx/web2022/contacto/" style={{color: "white"}}> Contáctanos</a></p>
                    </div>
                    
                    <div>
                        <p> <a href="https://inroads.org.mx/aviso-de-privacidad/" style={{color: "white"}}> Aviso de Privacidad </a></p>
                        <p> <a href="https://wa.me/8134715109" style={{color: "white"}}> Tel:  56 3388 9254<br />
                                                                                                56 3388 9264<br />
                                                                                                56 2463 0532<br />
                                                                                                56 1024 4770</a></p>
                        <p> <a href="mailto:subete.nube.google@inroads.org.mx" style={{color: "white"}}>subete.nube.google@inroads.org.mx</a></p>
                    </div>
                </div>
                <div className={style['container-footer_redes_sociales']}>
                    <a href="https://www.facebook.com/INROADS.Mexico/" target="_blank" rel="noopener noreferrer">
                        <img src={FacebookIcon} alt="Facebook" />
                    </a>
                    <a href="https://www.youtube.com/c/INROADSdeM%C3%A9xicoAC" target="_blank" rel="noopener noreferrer">
                        <img src={Youtube} alt="Youtube" />
                    </a>
                    <a href="https://www.instagram.com/inroads_mx/" target="_blank" rel="noopener noreferrer">
                        <img src={InstaIcon} alt="Instagram" />
                    </a>
                    <a href="https://www.linkedin.com/company/inroads-de-m-xico-a-c-/" target="_blank" rel="noopener noreferrer">
                        <img src={LinkedInIcon} alt="Linkedin"/>
                    </a>
                    <a href="https://wa.me/8134715109" target="_blank" rel="noopener noreferrer">
                        <img src={Whats} alt="WhatsApp" />
                    </a>
                </div>
                <div className={style['container-footer_derechos']}>
                    <p>© 2023 INROADS de México. Todos los derechos reservados.</p>
                </div>
            </div>
        </div>
    )
};
