import React from 'react';
import style from './RequiredInfoIns.module.css';

export default function RequiredInfo() {
    return(
        <div className={style.container + ' margin-top-md'}>
            <div className={style['sub-container'] + ' container-margin'}>
                <div className={style['container-left']}>
                    <iframe height="315" src="https://www.youtube.com/embed/QoUQlotwSi0"
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                    </iframe>
                </div>
                <div className={style['container-right']}>
                    <div className={style.title}>
                        Agenda de las clases en vivo del próximo programa <br/> Del 4 de febrero al 13 de febrero<br/>
                    </div>
                    <br />
                    <div className={style.text}>
                        <p>
                            <b>¿Cuándo?</b> martes y jueves<br />
                            <b>Horario:</b> 16:00 a 18:00 hrs. (hora central CDMX) <br />
                            <b>¿Dónde?</b> En vivo online desde tu perfil de Súbete a la Nube.
                            <br />
                            <br />
                            (Cupo limitado, INROADS se reserva el derecho de admisión)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};